/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
div.navi {
  float: left;
  display: block;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
div.sub1 {
  margin-left: 210px;
  margin-top: 58px;
}
@media (max-width: 1310px) {
  div.sub1 {
    float: right;
    margin-left: 0;
  }
}
div.sub1 > .item {
  margin: 0 36px;
}
@media (max-width: 1310px) {
  div.sub1 > .item {
    margin: 0 20px;
  }
}
div.sub1 > .item.init {
  margin-left: 0;
}
div.sub1 > .item.exit {
  margin-right: 0;
}
div.sub1 > .item > .menu {
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 10px 6px 10px;
  position: relative;
}
div.sub1 > .item > .menu:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 1px;
  background: #000;
  transition: all 0.2s ease-out;
}
div.sub1 > .item > .menu:hover:after,
div.sub1 > .item > .menu:focus:after,
div.sub1 > .item > .menu.path:after {
  width: 100%;
  left: 0;
}
.desk {
  max-width: 92%;
}
.section--primo .area {
  width: 100%;
}
.section--primo .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--primo .area .foot {
  margin-right: 2.63157895%;
  margin-left: 2.63157895%;
}
.section--primo .area .part {
  margin-right: 2.63157895%;
  margin-left: 2.63157895%;
  width: 94.73684211%;
}
.section--primo .area .tiny {
  width: 44.73684211%;
}
.section--primo .area > .slim .part {
  width: 94.73684211%;
}
.section--primo .area > .slim .tiny {
  width: 44.73684211%;
}
.section--secondo .area {
  width: 100%;
}
.section--secondo .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--secondo .area .foot {
  margin-right: 1.77725118%;
  margin-left: 1.77725118%;
}
.section--secondo .area .part {
  margin-right: 1.77725118%;
  margin-left: 1.77725118%;
  width: 96.44549763%;
}
.section--secondo .area .tiny {
  width: 46.44549763%;
}
.section--secondo .area > .slim {
  width: 50%;
}
.section--secondo .area > .slim .foot,
.section--secondo .area > .slim .part {
  margin-right: 3.55450237%;
  margin-left: 3.55450237%;
}
.section--secondo .area > .slim .part {
  width: 92.89099526%;
}
.section--secondo .area > .slim .tiny {
  width: 42.89099526%;
}
.section--terzo .area {
  width: 100%;
}
.section--terzo .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--terzo .area .foot {
  margin-right: 1.77725118%;
  margin-left: 1.77725118%;
}
.section--terzo .area .part {
  margin-right: 1.77725118%;
  margin-left: 1.77725118%;
  width: 96.44549763%;
}
.section--terzo .area .tiny {
  width: 46.44549763%;
}
.section--terzo .area > .slim {
  width: 50%;
}
.section--terzo .area > .slim .foot,
.section--terzo .area > .slim .part {
  margin-right: 3.55450237%;
  margin-left: 3.55450237%;
}
.section--terzo .area > .slim .part {
  width: 92.89099526%;
}
.section--terzo .area > .slim .tiny {
  width: 42.89099526%;
}
.section--quarto .area {
  width: 100%;
}
.section--quarto .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--quarto .area .foot {
  margin-right: 0.72639225%;
  margin-left: 0.72639225%;
}
.section--quarto .area .part {
  margin-right: 0.72639225%;
  margin-left: 0.72639225%;
  width: 98.5472155%;
}
.section--quarto .area .tiny {
  width: 23.5472155%;
}
.section--quarto .area > .slim {
  width: 25%;
}
.section--quarto .area > .slim .foot,
.section--quarto .area > .slim .part {
  margin-right: 2.90556901%;
  margin-left: 2.90556901%;
}
.section--quarto .area > .slim .part {
  width: 94.18886199%;
}
.section--quarto .area > .slim .tiny {
  width: 94.18886199%;
}
.section--primo .area {
  width: 105.55555556%;
  margin-left: -2.77777778%;
}
.section--terzo .area {
  width: 103.68550369%;
  margin-left: -1.84275184%;
}
.section--secondo .area {
  width: 103.68550369%;
  margin-left: -1.84275184%;
}
.section--quarto .area {
  width: 101.47420147%;
  margin-left: -0.73710074%;
}
.section--primo .area div.unit.slim {
  width: 75.43859649%;
  margin-left: 12.28070175%;
  margin-right: 12.28070175%;
}
.section--primo .area div.unit.slim div.part {
  margin-left: 3.48837209%;
  margin-right: 3.48837209%;
  width: 93.02325581%;
}
.section--primo .area div.unit.slim div.part.tiny {
  width: 43.02325581%;
}
.section--secondo .area div.seam {
  margin-left: 1.77725118%;
  margin-right: 1.77725118%;
  width: 46.44549763%;
}
.section--secondo .area div.flat {
  margin-left: 1.77725118%;
  margin-right: 1.77725118%;
  width: 46.44549763%;
}
.section--secondo .area div.flat div.tall {
  float: right;
  width: 72.44897959%;
}
.section--secondo .area div.flat div.tiny {
  float: left;
  width: 19.89795918%;
}
.section--quarto .area div.seam {
  margin-left: 0.72639225%;
  margin-right: 0.72639225%;
}
.section--quarto .area div.seam.wide {
  width: 98.5472155%;
}
.section--quarto .area div.seam.wide div.part {
  margin-left: 1.84275184%;
  margin-right: 1.84275184%;
}
.section--quarto .area div.seam.wide div.part.tall {
  width: 96.31449631%;
}
.section--quarto .area div.seam.wide div.part.tiny {
  width: 21.31449631%;
}
.section--quarto .area div.seam.slim {
  width: 23.5472155%;
}
.section--quarto .area div.seam.slim div.part {
  margin-left: 7.71208226%;
  margin-right: 7.71208226%;
  width: 84.57583548%;
}
.section--quarto .area div.seam div.pict {
  background: #000;
  overflow: hidden;
}
.section--quarto .area div.seam div.pict img {
  transition: all 0.4s;
}
.section--quarto .area div.seam:hover div.pict img {
  opacity: 0.6;
  transform: scale(1.1);
}
.cb-album div.ctrl div.prev {
  margin-left: 2.63157895%;
}
.cb-album div.ctrl div.next {
  margin-right: 2.63157895%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #000;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #000;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #000000;
}
.mese .same {
  background: none;
}
/******* module-filter-large.less 2013-1-16 *******/
/*.beta span > .cb-hybrid,
.days td > .cb-hybrid,
.week td > .cb-hybrid,
.year td > .cb-hybrid,
.cats li > .cb-hybrid {
  text-decoration: underline;
}*/
.mese td > .cb-hybrid {
  background-color: #080808;
}
.mese td > .cb-hybrid:hover,
.mese td > .cb-hybrid:focus {
  background-color: #0d0d0d;
}
.mese tbody th,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding: 0 0.3em;
}
.mese tbody th,
.mese tbody td {
  padding: 0 4% 0 2%;
}
.mese td > .cb-hybrid,
.mese td > span {
  padding: 0 0.1em;
}
.mese td > span {
  display: block;
}
.tabs .cb-hybrid,
.tabs .same > span,
.time .cb-hybrid,
.time .same > span,
.keys input,
.scan select {
  padding: 0;
}
.scan select {
  margin: 0;
  width: 100%;
}
.keys input {
  float: left;
  margin: 0 2% 0 0;
  padding: 0.23em;
  width: 71%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.keys button.cb-hybrid {
  display: block;
  float: left;
}
.font9 {
  font-size: 9px;
  font-size: 0.9rem;
}
.font12 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font15 {
  font-size: 15px;
  font-size: 1.5rem;
}
.font18 {
  font-size: 18px;
  font-size: 1.8rem;
}
.font21 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font24 {
  font-size: 24px;
  font-size: 2.4rem;
}
.font27 {
  font-size: 27px;
  font-size: 2.7rem;
}
.font30 {
  font-size: 30px;
  font-size: 3rem;
}
.font32 {
  font-size: 32px;
  font-size: 3.2rem;
}
/*# sourceMappingURL=./screen-large.css.map */